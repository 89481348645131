body {
  margin: 0;
  background:#f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*.mapboxgl-control-container{*/
/*  display: none;*/
/*}*/
*:focus {
  outline: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes wave {
  from { transform: translateY(0); }
  to { transform: translateY(-1em); }
}


.wavey span {
  display: inline-block;
  animation-duration: 0.2s;
  animation-name: wave;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

path {
  shape-rendering: geometricPrecision;
}